import { ElementRef, inject, Injectable, signal } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthHttpClient } from "../auth/auth-client.service";
import { EntityType } from "@libs/shares/models/models";
import { EntityVerificationDto, VerificationType } from "@libs/shares/dto/entityVerificationDto";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import snsWebSdk, { SnsWebSdk } from "@sumsub/websdk";
import { captureException } from "@sentry/angular";
import { UnleashToggleService } from "../../domain/unleash/unlease.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { TrackingService } from "../analytics/analytics.service";

@Injectable({
  providedIn: "root"
})
export class CrmService {
  verificationInfo$ = new BehaviorSubject<EntityVerificationDto | undefined>(undefined);
  isVerified$ = new BehaviorSubject<boolean | undefined>(undefined);
  sumsubAppplicantLoaded = signal(false);
  isSumSubSdkInitialized = signal(false);
  sumSubInstance = signal<SnsWebSdk | null>(null);
  private unleashService = inject(UnleashToggleService);
  isSumsubActive = toSignal(this.unleashService.getFeature$("sumsubAtivation"));
  isInitiatingVerification = signal(false);
  trackingService = inject(TrackingService);
  constructor(private authHttpClient: AuthHttpClient) {}

  sendUpgradeRequest(
    orgId: string,
    body: Partial<{
      organizationName: string | null;
      firstName: string | null;
      lastName: string | null;
      organizationUrl: string | null;
      comment: string | null;
      entityType: EntityType | null;
      verificationType: VerificationType | null;
      monthlyLimit: number | null;
      rps: number | null;
    }>
  ) {
    return this.authHttpClient.post(`${environment.apiBaseUrl}/crm/${orgId}`, body);
  }

  async getVerificationInfo(orgId: string, entityType: EntityType | null) {
    return firstValueFrom(
      this.authHttpClient.get<EntityVerificationDto>(`${environment.apiBaseUrl}/crm/kyb/${orgId}?type=${entityType}`)
    ).then((info) => {
      this.verificationInfo$.next(info);
      return info;
    });
  }

  async isVerified(orgId: string) {
    return firstValueFrom(
      this.authHttpClient.get<boolean>(
        `${environment.apiBaseUrl}/crm/kyb/${orgId}/isVerified?verificationType=approved`
      )
    );
  }

  async initiateVerification(orgId: string, entityType: EntityType, verificationType: VerificationType) {
    return firstValueFrom(
      this.authHttpClient.post<EntityVerificationDto>(
        `${environment.apiBaseUrl}/crm/kyb/${orgId}/${entityType}/${verificationType}`,
        {}
      )
    ).then((info) => {
      this.verificationInfo$.next(info);
      return info;
    });
  }

  async launchWebSdk(
    div: HTMLDivElement,
    orgId: string,
    entityType: EntityType,
    verificationType: VerificationType,
    applicantEmail?: string,
    applicantPhone?: string
  ) {
    const { token: accessToken } = await this.getNewAccessToken(orgId, verificationType);
    return new Promise<SnsWebSdk>((resolve) => {
      const snsWebSdkInstance = snsWebSdk
        .init(
          accessToken,
          // token update callback, must return Promise
          // Access token expired
          // get a new one and pass it to the callback to re-initiate the WebSDK
          () => this.getNewAccessToken(orgId, verificationType).then((res) => res.token)
        )
        .withConf({
          lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          theme: "dark"
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        .on("idCheck.onApplicantLoaded", (payload) => {
          this.sumsubAppplicantLoaded.set(false);
          this.saveVerification(orgId, entityType, verificationType, payload.applicantId);
        })
        .on("idCheck.onError", (error) => {
          captureException(error, { extra: { orgId } });
        })
        .on("idCheck.onInitialized", () => {
          resolve(snsWebSdkInstance);
        })
        .build();

      snsWebSdkInstance.launch(div);
    });
  }

  getNewAccessToken(orgId: string, verificationType: VerificationType) {
    return firstValueFrom(
      this.authHttpClient.get<{ token: string }>(`${environment.apiBaseUrl}/crm/sumsub/${verificationType}/${orgId}`)
    );
  }

  async saveVerification(
    orgId: string,
    entityType: EntityType,
    verificationType: VerificationType,
    applicantId: string
  ) {
    return firstValueFrom(
      this.authHttpClient.post<EntityVerificationDto>(
        `${environment.apiBaseUrl}/crm/sumsub/create/${orgId}/${verificationType}/${entityType}`,
        { applicantId }
      )
    ).then((info) => {
      this.verificationInfo$.next(info);
      return info;
    });
  }

  async loadSumSub(orgId: string, entityType: EntityType, element: ElementRef<HTMLDivElement>) {
    const verificationType = this.verificationType(entityType);

    if (verificationType) {
      this.isInitiatingVerification.set(true);
      try {
        const instance = await this.launchWebSdk(element.nativeElement, orgId, entityType, verificationType);
        this.sumSubInstance.set(instance);
        this.isSumSubSdkInitialized.set(true);
      } catch (error) {
        captureException(error);
      } finally {
        this.isInitiatingVerification.set(false);
      }
    }
  }

  verificationType(entityType: EntityType) {
    return entityType === EntityType.individual
      ? VerificationType.kyc
      : entityType === EntityType.corporate
        ? VerificationType.kyb
        : undefined;
  }

  async verify(
    orgId: string,
    entityType: EntityType,
    elementRef: ElementRef,
    kycInfo: EntityVerificationDto | undefined
  ) {
    this.trackingService.trackEvent("verification-registration", {
      event_action: "click",
      event_label: "initiate",
      event_type: "button"
    });
    const verificationType = this.verificationType(entityType);

    if (this.isSumsubActive() && verificationType) {
      await this.loadSumSub(orgId, entityType, elementRef);
      return;
    } else {
      this.isInitiatingVerification.set(true);
      try {
        if (entityType && verificationType) {
          await this.initiateVerification(orgId, entityType, verificationType);
        }
        const verificationInfo = kycInfo;
        if (verificationInfo?.link) {
          window.open(verificationInfo.link, "blank");
        }
      } catch (error) {
        captureException(error);
      } finally {
        this.isInitiatingVerification.set(false);
      }
    }
  }
}
